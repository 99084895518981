import "react-toastify/dist/ReactToastify.min.css";
import "react-calendar/dist/Calendar.css";

import { AppProps } from "next/dist/shared/lib/router/router";
import { useRouter } from "next/router";
import { SessionProvider } from "next-auth/react";
import React, { FunctionComponent, useMemo } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ToastContainer } from "react-toastify";
import { SWRConfig } from "swr";

import { MainLayout } from "../components/Layout";
import { SurveyModal } from "../components/Survey/SurveyModal";
import { RECAPTCHA_SITE_KEY } from "../config/client";
import { StickMenuProvider, TextThemeContextProvider } from "../contexts";
import { UserAgentContext } from "../hooks/useMobile";
import { pagesPath } from "../lib/$path";
import { AxiosProvider } from "../lib/apiClient";

const PagesWithoutSurveyModal = [
  pagesPath.sign_in.$url().pathname as string,
  pagesPath.sign_up.$url().pathname as string,
  pagesPath.reset_password.$url().pathname as string,
  pagesPath.reset_password_requested.$url().pathname as string,
  pagesPath.jobseekers.welcome.$url().pathname as string,
  pagesPath.$404.$url().pathname as string,
] as const;

const App: FunctionComponent<AppProps> = ({
  Component,
  pageProps: { fallback, session, userAgent, ...pageProps },
}: AppProps) => {
  const router = useRouter();

  const withoutSurveyModal = useMemo(
    () => PagesWithoutSurveyModal.includes(router.pathname),
    [router.pathname],
  );

  return (
    <React.StrictMode>
      <SessionProvider session={session}>
        <AxiosProvider session={session}>
          <SWRConfig value={{ fallback }}>
            <UserAgentContext.Provider
              value={
                typeof navigator === "undefined"
                  ? userAgent
                  : (navigator.userAgent ?? null)
              }
            >
              <GoogleReCaptchaProvider
                reCaptchaKey={RECAPTCHA_SITE_KEY}
                language="ja"
              >
                <TextThemeContextProvider>
                  <StickMenuProvider>
                    <MainLayout>
                      <Component {...pageProps} />
                      {!withoutSurveyModal && <SurveyModal />}
                      <ToastContainer />
                    </MainLayout>
                  </StickMenuProvider>
                </TextThemeContextProvider>
              </GoogleReCaptchaProvider>
            </UserAgentContext.Provider>
          </SWRConfig>
        </AxiosProvider>
      </SessionProvider>
    </React.StrictMode>
  );
};

export default App;
